.navbar {
  background-color: transparent;
  color: #f3ecec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  transition: background-color 0.5s ease-in-out;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  z-index: 1;
  /* font-family: "Camphor", sans-serif; */
  font-size: 16px;
  font-weight: 200;
}


.navbar-scrolled {
  background-color: transparent !important;
}

.navbar.dark {
  background-color: transparent;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
}

.navbar li {
  margin-left: auto;
  position: relative; /* add this */
}

.navbar a {
  text-decoration: none;
  color: #ffffff;
}

.navbar a:hover {
  color: #ff9900;
  text-decoration: none;
}

.navbar-search {
  margin-left: auto;
}

.navbar-search form {
  display: flex;
  align-items: center;
}

.navbar-search input {
  background-color: #f3ecec;
  color: #555;
  border: none;
  padding: 10px;
  font-size: 13px;
  border-radius: 5px;
  width: 150px;
  margin-right: 10px;
}

.navbar-search button {
  background-color: #ff9900;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f3ecec;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 150px;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.search-results a {
  color: #555;
  padding: 10px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 10px;
}

.search-results a:hover {
  background-color: #ff9900;
  color: #f3ecec;
}


/* .navbar-search {
  margin-left: auto;
}

.navbar-search form {
  display: flex;
  align-items: center;
} */

.navbar-search button {
  background-color: #ff9900;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}


.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: #f3ecec;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 120px;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px
}

.dropdown-menu a {
  color: #555;
  padding: 10px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 10px
}

.dropdown-menu a:hover {
  background-color: #ff9900;
  color: #f3ecec;
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
}

.dropdown-menu ul a {
  color: #555;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu ul a:hover {
  background-color: #8f8f8f;
  color: #f3ecec;
}



/* Style the home page */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Camphor", sans-serif;
  opacity: 2;
  margin-top: 100px;
}

body {
  background-image: url('https://i.ibb.co/gFC53k0/rand.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  opacity: 80%;
  font-family: 'Open Sans', sans-serif;
  
}

.columns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 90px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 30%;
  min-height: 400px;
  padding: 20px;
  margin-right: 20px;
}


.column:last-child {
  margin-right: 0;
}

.column h3 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.column ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.column a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  margin-bottom: 10px;
}

.column a:hover {
  color: #0275d8;
}
/* Responsive layout */
@media screen and (max-width: 768px) {
  .home {
    flex-direction: column;
  }
}

.navbar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  font-family: "Camphor", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #ff9900;
  margin-top: 80px;
}

.investment-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  font-family: "Camphor", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #ff9900;
  margin-bottom: -20px;
}

.graph-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  height: 400px;
}

@media (max-width: 768px) {
  .graph-container {
    margin: 0 auto;
    width: 80%;
  }
}

.graphs {
  text-align: center;
}

.graph-container iframe {
  width: 600px;
  height: 370px;
  transform: scale(0.8);
  border-radius: 5px;
  margin-bottom: 50px;
}

.graph h3 {
  color: #ff9900;
  margin-bottom: -30px;
}
.checklist-container {
  background-color: #171c24;
  color: #ffffff;
  border-radius: 15px;
  width: 80%;
  margin: auto;
  padding: 20px;
}

.checklist-container h2 {
  text-align: left;
  font-size: 24px;
  margin-bottom: 10px;
}

.checklist-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.checklist-container li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.checklist-container label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.checklist-container input[type="checkbox"] {
  margin-right: 10px;
  flex: 0 0 auto;
}

.checklist-container input[type="checkbox"]:checked + span {
  text-decoration: line-through;
  opacity: 0.5;
}

.checklist-container span {
  font-size: 18px;
  line-height: 1.5;
  flex: 1;
  text-align: left;
}



/* Center the iframe */
iframe {
  display: block;
  margin: 0 auto;
  padding: 20px;
}

.card {
  background-color: #171c24;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Arial, sans-serif;
}

.card h2 {
  margin-bottom: 1rem;
  color: #ffffff;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.card ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.card li {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card li::before {
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #ff9900;
  margin-right: 0.5rem;
}

.card h1 {
  color: #ff9900;
}

.container {
  margin-top: 8rem;
}


.todo-container {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  text-align: center;
}

.todo-container h1{
  color: #ff9900;
}

.todo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 2rem auto;
  max-width: 800px;
}

.todo-row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
}

.todo-card {
  background-color: #171c24;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: calc(25% - 0.5rem);
  text-align: center;
  opacity: 500%;
}

.todo-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.todo-card li {
  margin-bottom: 10px;
}

.todo-card a {
  color: #fff;
  text-decoration: none;
}

.todo-card a:hover {
  text-decoration: underline;
}


.todo-card h2 {
  color: #ff9900;
}

.header-text h1{
  color: #ff9900;
  margin-top: 100px;
}

h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #002e7a;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 0.5rem;
}

a {
  color: #ffffff;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
}

a:hover {
  text-decoration: underline;
}

.header-text {
  text-align: center;
  margin-bottom: 1rem;
}

.image-container {
  text-align: center;
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .todo-row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .todo-card {
    width: calc(50% - 1rem);
    margin-bottom: 2rem;
  }
}

.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1000px;
}

.project-card {
  width: 650px;
  height: 500px;
  margin: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart {
  width: calc(100% + 20px);
  height: 10%;
  border: none;
}

.chart-row{
  margin-right: 55px;
  justify-content: center;
  align-items: center;
}

.docs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.docs-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  height: 300px;
  margin: 10px;
  padding: 20px;
  margin-bottom: 80px;
  border-radius: 10px;
  background-color: #171c24;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  opacity: 80%;
}

.docs-card-body {
  list-style: none;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin-bottom: 40px;
}

.docs-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.docs-card-title {
  color: #ffffff;
  margin-top: 0px;
}

.docs-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.docs-body a {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  list-style: none;
}

.docs-body a:hover {
  color: #ff9900;
  transform: scale(1.1);
}

.footer {
  background-color: #171c24;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  font-family: Arial, Helvetica, sans-serif;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.social-links a {
  display: inline-block;
  margin: 0 10px;
  color: white;
  text-decoration: none;
}
.project-graph {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;

}

.project-graph h1 {
  margin-top: 10rem;
  font-size: 2rem;
  color: #ff9900;
  margin-bottom: 2rem;
}

.project-chart-container {
  display: inline-block;
  width: calc(33% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  transform: scale(0.8);
}

.project-chart-container h2{
  color: white;
}
.chart-title {
  font-size: 1rem;
  color: white;
  margin-bottom: 0.5rem;
}

project.chart-container iframe {
  width: 100%;
  border: none;
  height: 300px;
  margin-right: 100px;
}

.upcoming-deadlines h1{
  color: black;
}

.google-calendar {
  margin-top: 2rem;
}

.google-calendar iframe {
  border: solid 1px #777;
}

.form-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 100px;
  
}

.form-button {
  background-color: #ff9900;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.form-button:hover {
  background-color: #ff9900;
  box-shadow: none;
}

.google-calendar {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}

.google-calendar h1 {
  color: #ff9900;
  font-size: 2rem;
  text-align: center;
  width: 100%;
}

.google-calendar iframe {
  border: solid 1px #777;
  height: 400px;
  margin-top: 20px;
  width: 600px;
}

@media (max-width: 768px) {
  .google-calendar iframe {
    width: 100%;
  }
}

/* 
.carousel {
  height: 65vh;
  margin-bottom: 0;
}

.carousel-item {
  padding-top: 10px;
  padding-bottom: 100px;
}

.carousel-indicators {
color: black; /* Adjust this value to your liking */

/* .carousel-wrapper {
  margin-bottom: 1rem;
} */

.carousel-container {
  position: relative;
}

.carousel-prev,
.carousel-next {
  content: "";
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  width: 20px;
  height: 30px;
  background-color: white;
  border-radius: 20%;
  border: none;
  outline: none;
  cursor: pointer;
  /* margin: 0 auto; */
}

.carousel-prev {
  left: 20px;
}

.carousel-next {
  right: 20px;
}

.carousel-prev::before,
.carousel-next::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-top: 2px solid #171c24;
  border-right: 2px solid #171c24;
  transform: rotate(360deg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel-next::before {
  transform: rotate(45deg);
}

.carousel-prev::before {
  transform: rotate(-135deg);
}


.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
  background-color: #171c24;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative;
}

.slide {
  display: none;
  width: 100%;
  max-width: 800px;
}

.slide h2{ 
  color: ff9900;
  text-align: center;
}

.slide h2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.slide.active {
  display: block;
  animation: slide-in 1.5s ease-in-out forwards;
}

.slide.active th{
  color: #ff9900;
}

.slide.active h2{
  margin-top: 50px;
}


@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}


thead {
  background-color: #444;
  color: white;
}


th {
  padding: 10px;
}

td {
  border: 1px solid #ccc;
  padding: 10px;
  color: white;
}

.slide-btn {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #444;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.slide-btn:hover {
  background-color: #555;
}




.mx-3 {
  background-color: #171c24 !important;
  width: 45%;
  margin: 0 1rem;
  padding: 1rem;
  border: 2px solid #00bfff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  position: absolute;
}

.mx-3 h3 {
  color: #fff;
  text-align: center;
  margin-bottom: 1rem;
}

.mx-3 table {
  width: 100%;
}

.mx-3 thead th {
  background-color: #171c24;
  color: #fff;
}

.mx-3 tbody tr:nth-child(odd) {
  background-color: #2d2d2d;
}

.mx-3 .card-striped tbody tr:nth-child(odd) {
  background-color: #404040;
}

.mx-3 .card-bordered td,
.mx-3 .card-bordered th {
  border: 2px solid #00bfff;
}

.login-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  }

.l33 h1{
  color: #ff9900;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #2c3e50;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
} 

form {
  display: flex;
  align-items: center;
}

label {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
  margin-right: 20px;
} 

.input-field {
  padding: 15px;
  border-radius: 10px;
  border: none;
  margin-bottom: 30px;
  width: 100%;
  font-size: 1.5rem;
  margin-right: 20px;
}

.modal-button {
  background-color: #27ae60;
  padding: 15px;
  margin-bottom: 40px;
}



/* .login-form2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  }  */


 
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #171c24;
}


.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
}


.login-form h1 {
  font-size: 24px;
  margin-bottom: 16px;
}


.login-form input {
  width: 100%;
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}



.login-form button {
  width: 100%;
  padding: 12px;
  background-color: #0077cc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}


.login-form button:hover {
  background-color: #0066aa;
}


.modal-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  display: absolute;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  max-width: 800px;
  width: 175%;
}


.modal-content h2 {
  margin-top: 0;
}



.modal-content button {
  margin-top: 10px;
}



.modal-content {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}



h1 {
  font-size: 2rem;
  margin-bottom: 50px;
  color: ff9900;
  text-align: center;
  text-transform: uppercase;
  }

  h2 {
  font-size: 1rem;
  margin-bottom: 30px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  }

/*kljfklsjdfjldf*/

label {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/*
input {
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
  width: 100%;
}
*/

/*LEAVE TAKEN OUT^ TILL END*/

button {
  background-color: white;
}

    
    
    .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    }
    
    .modal-content {
    background-color: #2c3e50;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    } 
    
  
    form {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* margin-bottom: 30px; */
    }


    
    label {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #fff;
    text-align: center;
    } 

    /* THIS IS THE PROBLEM FOR CHECKLIST VVV*/

    /* input {
    padding: 15px;
    border-radius: 10px;
    border: none;
    margin-bottom: 30px;
    width: 100%;
    font-size: 1.5rem;
    } */

    .input-field {
      padding: 15px;
      border-radius: 10px;
      border: none;
      margin-bottom: 30px;
      width: 150px;
      font-size: 1.5rem;
    }

    
    button {
    background-color: #27ae60;
    color: #fff;
    border-radius: 10px;
    border: none;
    padding: 15px 40px;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    }
    
    button:hover {
    background-color: #ff9900;
    }
    
    button:active {
    transform: scale(0.95);
    }

.login-container h1{
  color: white;
  font-size: 2rem;
}  

/* 
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-8%);
  width: auto;
  margin: 0;
  border-radius: 0;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}


.container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: 300px;
  margin-bottom: 300px;
}

.card2 {
  background-color: #171c24;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  max-width: 80%;
  width: 1000px;
}

.card2 h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color:white;
}

.card3 {
  margin-top: 1.5rem;
  color: white
}

.card3 h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  
  color: #ff9900;
}

.card3 ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}

.card3 li {
  margin-bottom: 0.5rem;
} */
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.24);
  border-radius: 10px;
  padding: 8px;
  width: 100%;
}

.search-results a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #333;
}

.search-results a:hover {
  background-color: #ff9900e3;
  color: black;
}

.hidden {
  display: none;
}

.to-do-lists-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  /* gap: rem; */
  margin: 20rem;
  margin-top: 4rem;
  align-content: center;
  margin-bottom: -2rem;
}

.to-do-list {
  background-color: #171c24;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.to-do-list-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.to-do-list-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.to-do-list-item {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
  flex-basis: 48%;
}

.to-do-list-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.to-do-list-button {
  background-color: #008080;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-top: 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.to-do-list-button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
}

.to-do-list-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.to-do-list-input input[type="text"] {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 3rem;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-size: 1.2rem;
  width: 100%;
  outline: none;
}
